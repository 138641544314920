import React, { useEffect } from "react";
import CabinetStatisticDetail from "./detail";
import { DeviceState } from "../../../../graphql/lightboxs";
import StatisticCabinetModal from "./modal";

interface ICabinetStatisticProps {
  data: any[];
  handleSelectItem: (id: number) => void;
  className: string;
}
const CabinetStatistic: React.FunctionComponent<ICabinetStatisticProps> = ({
  data,
  handleSelectItem,
  className,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [selected, setSelected] = React.useState(DeviceState.ERROR);
  const [errorCount, setErrorCount] = React.useState(0);
  const [normalCount, setNormalCount] = React.useState(0);
  const [lightOnCount, setLightOnCount] = React.useState(0);
  const [savingEnergyCount, setSavingEnergyCount] = React.useState(0);
  useEffect(() => {
    if (data) {
      setErrorCount(
        data.filter((i) => i.device.currentState === DeviceState.ERROR).length
      );
      setNormalCount(
        data.filter((i) => i.device.currentState === DeviceState.NORMAL).length
      );
      setLightOnCount(
        data.filter((i) => i.device.currentState === DeviceState.LIGHT_ON)
          .length
      );
      // setSavingEnergyCount(
      //   data.filter((i) => i.device.currentState === DeviceState.SAVING_ENERGY)
      //     .length
      // );
    }
  }, [data]);
  return (
    <>
      <StatisticCabinetModal
        open={showModal}
        data={data}
        handleSelectItem={handleSelectItem}
        activeKey={selected}
        handleCancel={() => {
          setShowModal(false);
        }}
        // onCancel={() => {
        //   setShowStatisticModal(false);
        // }}
        // lightboxId={showDetailLightbox.id}
      />
      <div className={className}>
        <div className="flex cursor-pointer" onClick={() => setShowModal(true)}>
          <CabinetStatisticDetail
            onClick={() => setSelected(DeviceState.ERROR)}
            type={DeviceState.ERROR}
            value={errorCount}
          />
          <CabinetStatisticDetail
            onClick={() => setSelected(DeviceState.NORMAL)}
            type={DeviceState.NORMAL}
            value={normalCount}
          />
          <CabinetStatisticDetail
            onClick={() => setSelected(DeviceState.LIGHT_ON)}
            type={DeviceState.LIGHT_ON}
            value={lightOnCount}
          />
          {/* <CabinetStatisticDetail
            onClick={() => setSelected(DeviceState.SAVING_ENERGY)}
            type={DeviceState.SAVING_ENERGY}
            value={savingEnergyCount}
          /> */}
        </div>
      </div>
    </>
  );
};

export default CabinetStatistic;
