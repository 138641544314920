import { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import CurrentLocation from "./currentLocation";
// @ts-ignore
import supercluster from "points-cluster";
import ClusterMarker from "./clusterMaker";
import LightboxPin from "./lightboxPin";
import DevicePin from "./devicePin";

interface IGMapProps {
  lightboxs: any[];
  devices: any[];
  onDeviceClick: (device: any) => void;
  selectedLightbox?: number;
  showItems: any;
}

const GMap = ({
  lightboxs,
  devices,
  onDeviceClick,
  selectedLightbox,
  showItems,
}: IGMapProps) => {
  const [registeredboxs, setRegisteredboxs] = useState<any[]>([]);
  const [unregisteredboxs, setUnregisteredboxs] = useState<any[]>([]);
  const [mapProps, setMapProps] = useState<any>({
    center: {
      lat: 10.756138200595704,
      lng: 106.66918725571473,
    },
    zoom: 9,
  });

  const [clusters1, setClusters1] = useState<any>([]); //registered
  const [clusters2, setClusters2] = useState<any>([]); //unregistered
  const [clusters3, setClusters3] = useState<any>([]); //devices

  const clRegistered = supercluster(registeredboxs, {
    minZoom: 3, // min zoom to generate clusters on
    maxZoom: 17, // max zoom level to cluster the points on
    radius: 100, // cluster radius in pixels
    minPoints: 2,
  });

  const clUnregistered = supercluster(unregisteredboxs, {
    minZoom: 3, // min zoom to generate clusters on
    maxZoom: 17, // max zoom level to cluster the points on
    radius: 100, // cluster radius in pixels
    minPoints: 2,
  });

  const clDevices = supercluster(devices, {
    minZoom: 3, // min zoom to generate clusters on
    maxZoom: 19, // max zoom level to cluster the points on
    radius: 100, // cluster radius in pixels
    minPoints: 2,
  });

  useEffect(() => {
    setRegisteredboxs(lightboxs.filter((i: any) => i.isRegistered));
    setUnregisteredboxs(lightboxs.filter((i: any) => !i.isRegistered));
  }, [lightboxs]);

  useEffect(() => {
    if (selectedLightbox) {
      const lightbox = lightboxs.find((i) => i.id === selectedLightbox);
      setMapProps({
        ...mapProps,
        zoom: 19,
        center: {
          lat: lightbox.lat,
          lng: lightbox.lng,
        },
      });
    }
  }, [selectedLightbox]);

  useEffect(() => {
    if (mapProps.bounds) {
      setClusters1(
        clRegistered({ ...mapProps }).map(
          ({ wx, wy, numPoints, points }: any) => ({
            lat: wy,
            lng: wx,
            lightboxs: points,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
          })
        )
      );
      setClusters2(
        clUnregistered({ ...mapProps }).map(
          ({ wx, wy, numPoints, points }: any) => ({
            lat: wy,
            lng: wx,
            lightboxs: points,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
          })
        )
      );
    }
  }, [mapProps, unregisteredboxs, registeredboxs]);

  useEffect(() => {
    if (mapProps.zoom > 3 && devices && devices.length > 0) {
      setClusters3(
        clDevices({ ...mapProps }).map(
          ({ wx, wy, numPoints, points }: any) => ({
            lat: wy,
            lng: wx,
            devices: points,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
          })
        )
      );
    }
  }, [mapProps, devices]);

  return (
    <div style={{ height: "94vh", width: "100%" }}>
      {/* <div
      className="absolute flex cursor-pointer justify-center pt-2 right-[10px] bg-variant-white z-50 mt-[60px] w-[40px] h-[40px] rounded-none outline-0"
      onClick={() => {
        setCenter({
          lat: currentLocation.latitude,
          lng: currentLocation.longitude,
        });
      }}
    >
      <EnvironmentFilled className="text-xl text-neutral-d50" />
    </div> */}
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: "AIzaSyCyviBaJdeM3N_9DW9iDnkI5X4nNWG9cak" }}
        // style={style}
        options={{
          minZoom: 3,
          maxZoom: 22,
        }}
        hoverDistance={30}
        center={mapProps.center}
        zoom={mapProps.zoom}
        onChange={({ center, zoom, bounds }) => {
          setMapProps({ center, zoom, bounds });
        }}
        // onChildMouseEnter={onChildMouseEnter}
        // onChildMouseLeave={onChildMouseLeave}
      >
        <CurrentLocation {...mapProps.center} />
        {showItems.registeredboxs &&
          clusters1.map(({ id, numPoints, lightboxs, ...markerProps }: any) =>
            numPoints === 1 ? (
              <LightboxPin key={id} lightbox={lightboxs[0]} {...markerProps} />
            ) : (
              <ClusterMarker
                key={id}
                numPoints={numPoints}
                {...markerProps}
                type={"registered"}
              />
            )
          )}
        {showItems.unregisteredboxs &&
          clusters2.map(({ id, numPoints, lightboxs, ...markerProps }: any) =>
            numPoints === 1 ? (
              <LightboxPin
                key={id}
                lightbox={lightboxs[0]}
                unregistered
                {...markerProps}
              />
            ) : (
              <ClusterMarker
                key={id}
                numPoints={numPoints}
                {...markerProps}
                type={"unregisterd"}
              />
            )
          )}

        {showItems.devices &&
          clusters3.map(({ id, numPoints, devices, ...markerProps }: any) =>
            numPoints === 1 ? (
              <DevicePin
                key={id}
                device={devices[0]}
                {...markerProps}
                onClick={onDeviceClick}
              />
            ) : (
              <ClusterMarker
                key={id}
                numPoints={numPoints}
                {...markerProps}
                type={"device"}
              />
            )
          )}
      </GoogleMapReact>
    </div>
  );
};

export default GMap;
