import { CloseOutlined, EditFilled, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Input, InputRef, message, Space, Table, TableColumnsType, TableColumnType, Tag } from "antd";
import confirm from "antd/lib/modal/confirm";
import type { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { Helmet } from "react-helmet";
import { DEFAULT_PAGE_SIZE, Order } from "../../graphql/common";
import {
  GET_USER_ALL,
  RESET_PASSWORD,
  SET_ACTIVE_USER,
  UPGRADE_ADMIN,
  User,
} from "../../graphql/users";
import CreateUserModal from "./components/CreateUserModal";
import UpdateUserModal from "./components/UpdateUserModal";
import { FilterDropdownProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";

interface IImportProps {}

const UserList: React.FunctionComponent<IImportProps> = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [username, setUsername] = React.useState("");
  const [users, setUsers] = React.useState<User[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<User>();
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE);
  const [searchText, setSearchText] = React.useState('');
  const [searchedColumn, setSearchedColumn] = React.useState('');
  const searchInput = React.useRef<InputRef>(null);
  const { refetch } = useQuery<{ users: User[] }, any>(GET_USER_ALL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      paging: {
        orderBy: [{
          field: 'roleId',
          order: Order.DESC
        }]
      }
    },
    onCompleted(data) {
      setUsers(data.users.map((i) => ({ ...i, key: i.id })));
    },
  });

  const [setActiveUser, setActiveUserResp] = useMutation<User, { id: string }>(
    SET_ACTIVE_USER
  );

  const [upgradeAdmin, setUpgradeAdminResp] = useMutation<User, { id: string }>(
    UPGRADE_ADMIN
  );

  const [resetPassword, setResetPasswordResp] = useMutation<
    boolean,
    { id: string }
  >(RESET_PASSWORD);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: any,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as any}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            <CloseOutlined></CloseOutlined>
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined className="px-2 text-base" style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<any> = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "SĐT",
      dataIndex: "phoneNumber",
      ...getColumnSearchProps('phoneNumber'),
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
    },
    {
      title: "Dự án",
      render: (_, { projects }) => {
        return projects.map((p: any) => (
          <>
            <Tag
              color={'blue'}
              key={p.id}
            >
              {p.name}
            </Tag>
          </>
        ));
      },
    },
    {
      title: "Quyền",
      render: (_, { id, role, roleId }) => {
        return (
          <>
            <Tag
              color={roleId === 3 ? "volcano" : roleId === 2 ? "orange" : "#8c8c8c"}
              key={id}
            >
              {role.name}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "isActived",
      render: (_, { id, isActived }) => {
        const color = isActived ? "blue" : "red";
        const value = isActived ? "Bình thường" : "Bị khóa";
        return (
          <>
            <Tag color={color} key={id}>
              {value}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Thao tác",
      key: "action",
      render: (_, user) => (
        <Space size="middle">
          <div
            className="flex gap-1 items-center justify-center cursor-pointer text-blue-primary hover:text-blue-d90"
            onClick={() => {
              setSelectedUser(user);
              setShowUpdateModal(true);
            }}
          >
            <EditFilled />
            <span>Chỉnh sửa</span>
          </div>
          {/* {!isAdmin ? (
            <div
              className="cursor-pointer text-blue-primary hover:text-blue-d90"
              onClick={() => upgradeAdminHandle(key)}
            >
              Up Admin
            </div>
          ) : (
            <></>
          )}
          <div
            className="cursor-pointer text-blue-primary hover:text-blue-d90"
            onClick={async () => {
              try {
                await setActiveUser({ variables: { id: key?.toString()! } });
                refetch();
                message.success("Thao tác Thành công");
              } catch (error: any) {
                const errMsg = error.toString().replace("Error: ", "");
                message.error("Thao tác thất bại " + errMsg);
              }
            }}
          >
            {isActived ? "Khóa" : "Mở khóa"}
          </div> */}
        </Space>
      ),
    },
  ];

  const resetPasswordHandle = async (key: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Reset về mật khẩu mặc định. Chắc chưa???",
      onOk: async () => {
        try {
          await resetPassword({ variables: { id: key?.toString()! } });
          message.success("Thao tác Thành công");
        } catch (error: any) {
          const errMsg = error.toString().replace("Error: ", "");
          message.error("Thao tác thất bại " + errMsg);
        }
      },
      onCancel() {},
    });
  };

  const upgradeAdminHandle = async (key: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Nâng cấp thành admin!!!",
      onOk: async () => {
        try {
          await resetPassword({ variables: { id: key?.toString()! } });
          message.success("Thao tác Thành công");
        } catch (error: any) {
          const errMsg = error.toString().replace("Error: ", "");
          message.error("Thao tác thất bại " + errMsg);
        }
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Citisys - Quản lý người dùng</title>
      </Helmet>
      <div className="p-[32px] flex flex-col flex-auto gap-[24px]">
        <div className="flex gap-[24px] items-center">
          <div className="text-[20px] md:text-[28px] font-bold">
            Danh sách người dùng
          </div>
          <CreateUserModal refetchList={refetch} />
        </div>
        <div className="flex-auto mt-[16px] relative overflow-scroll">
          <Table columns={columns} dataSource={users} pagination={false} />
        </div>
        <UpdateUserModal
          refetchList={refetch}
          visible={showUpdateModal}
          onCancel={() => {
            setShowUpdateModal(false);
          }}
          user={selectedUser}
        />
        {/* <div className="text-right">
          <Pagination
            showTotal={(total, range) => `Có ${total} kết quả`}
            showSizeChanger
            defaultCurrent={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={(page, pageSize) => {
              setCurrentPage(page + 1);
              setPageSize(pageSize);
            }}
          />
        </div> */}
      </div>
    </>
  );
};

export default UserList;
