import * as React from "react";

import {
  ScheduleOutlined,
  SearchOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Checkbox,
  Input,
  InputRef,
  Modal,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";
import { ColumnType, ColumnsType } from "antd/lib/table";
import type { FilterConfirmProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { useDebounce } from "use-debounce";
import { AuthContext } from "../../context/AuthContext";
import { GET_UNREGISTERED_DEVICES } from "../../graphql/devices";
import { GET_LIGHTBOXS_3, LightBox } from "../../graphql/lightboxs";
import { GET_PROJECTS } from "../../graphql/project";
import GMap from "../components/Map/map3";
import CreateRemoteRegisterModal from "./components/createRemoteRegisterModal";
import RegisterHistory from "./components/registerHistory";
import "./index.css";
import { GET_REGISTER_LOGS } from "../../graphql/registerLogs";
import { Helmet } from "react-helmet";

const CheckboxGroup = Checkbox.Group;
interface IRemoteRegisterProps {}

const RemoteRegister: React.FunctionComponent<IRemoteRegisterProps> = (
  props
) => {
  const { userInfo } = React.useContext(AuthContext);
  const [zoom, setZoom] = React.useState(13);
  const [devices, setDevices] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState(null);
  const [selectedLightbox, setSelectedLightbox] = React.useState(undefined);
  const [filterInput, setFilterInput] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [showItems, setShowItems] = React.useState({
    unregisteredboxs: true,
    registeredboxs: true,
    devices: true,
  });
  const [currentPage, setCurrentPage] = React.useState(1);
  const [projects, setProjects] = React.useState([]);
  const [lightboxs, setLightboxs] = React.useState<any[]>([]);
  const [searchlightboxs, setSearchLightboxs] = React.useState([]);
  const [filter, setFilter] = React.useState<{
    isRegistered?: boolean;
    projectId?: number;
  }>({
    // isRegistered: false,
    projectId: -1,
  });
  const [filterText] = useDebounce(filterInput, 600);
  useQuery(GET_UNREGISTERED_DEVICES, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setDevices(
        data.devicesForRemoteRegister.map((i: any) => ({
          ...i,
          ...i.liveData,
        }))
      );
    },
  });

  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<InputRef>(null);

  const [lightboxsQuery] = useLazyQuery(GET_LIGHTBOXS_3);
  const [lightboxsQueryText] = useLazyQuery(GET_LIGHTBOXS_3);

  useQuery(GET_PROJECTS, {
    fetchPolicy: "no-cache",
    onCompleted(rs) {
      const sorted = rs.projects.sort(
        (a: any, b: any) => b.createdAt - a.createdAt
      );
      setProjects(sorted);
    },
  });

  const [registerLogs, setRegisterLogs] = React.useState<any[]>([]);
  useQuery(GET_REGISTER_LOGS, {
    variables: {
      input: {
        projectId: filter.projectId,
      },
      paging: {
        orderBy: [{ field: "createdAt", order: "DESC" }],
      },
    },
    onCompleted(d) {
      setRegisterLogs(d.registerlogs.map((i: any) => ({ ...i, key: i.id })));
    },
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  // const { confirm } = Modal;

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input.Search
          className="block"
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as any}
          allowClear
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            if (!e.target.value || e.target.value === "") {
              clearFilters && clearFilters();
              setSearchText("");
              confirm();
            }
          }}
          onSearch={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8 }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        className="flex items-center text-base mr-2"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "key",
      width: 50,
    },
    {
      title: "Mã tủ",
      dataIndex: "boxId",
      width: 80,
      sorter: (a, b) => (a.boxId > b.boxId ? -1 : a.boxId < b.boxId ? 1 : 0),
    },
    {
      title: "Tên tủ",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      render: (_, { name, key }) => {
        return searchedColumn === "name" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            className="cursor-pointer hover:underline"
            onClick={() => setSelectedLightbox(key)}
            textToHighlight={name ? name.toString() : ""}
          />
        ) : (
          <div
            className="cursor-pointer hover:underline"
            onClick={() => setSelectedLightbox(key)}
          >
            {name}
          </div>
        );
      },
      // render: (_, { name, key }) => {
      //   return (
      //     <div
      //       className="cursor-pointer hover:underline"
      //       onClick={() => setSelectedLightbox(key)}
      //     >
      //       {name}
      //     </div>
      //   );
      // },
      // width: 100,
    },
    // {
    //   title: "Địa chỉ",
    //   dataIndex: "address",
    //   // width: 100,
    // },
    {
      title: "Thiết bị",
      dataIndex: "clientId",
      sorter: (a, b) => {
        if (!b.clientId) return 1;
        if (!a.clientId) return -1;
        return a.clientId > b.clientId ? 1 : a.clientId < b.clientId ? -1 : 0;
      },
      render: (_, { clientId }) => {
        return (
          <Tag
            className="text-base break-all"
            color={clientId ? "blue" : "volcano"}
          >
            {clientId ? clientId : "N/A"}
          </Tag>
        );
      },
    },
  ];

  const lightboxMapFunction = (i: LightBox) => ({
    key: i.id,
    boxId: i.boxId,
    name: i.name,
    clientId: i.clientId,
    address: i.address,
    // deviceId: i.clientId,
    // status: i.status,
    // isConnected: i.isConnected,
    // time: i.time,
    // lightbox: i.lightbox,
  });

  React.useEffect(() => {
    lightboxsQuery({
      variables: {
        input: filter,
      },
      onCompleted(data) {
        setLightboxs(data.lightboxs);
      },
    });
  }, [filter, lightboxsQuery]);

  React.useEffect(() => {
    lightboxsQueryText({
      variables: {
        input: {
          ...filter,
          text: filterText,
        },
        paging: {
          count: 6,
        },
      },
      onCompleted(data) {
        setSearchLightboxs(data.lightboxs);
      },
    });
  }, [filterText, lightboxsQueryText]);

  React.useEffect(() => {
    if (!showItems.registeredboxs && !showItems.unregisteredboxs)
      setLightboxs([]);
  }, [showItems]);

  const handleClick = (device: any) => {
    setSelected(device);
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Citisys - Đăng kí tủ từ xa</title>
        </Helmet>
        <div className="md:w-[640px] max-h-[300px] md:max-h-[auto] px-4 flex flex-col flex-auto gap-[24px] overflow-auto md:overflow-visible">
          <Tabs>
            <Tabs.TabPane
              tab={
                <span className="text-base">
                  <TableOutlined />
                  Danh sách tủ
                </span>
              }
              key="1"
            >
              <Table
                columns={columns}
                dataSource={
                  showItems.registeredboxs || showItems.unregisteredboxs
                    ? lightboxs.map((i) => lightboxMapFunction(i))
                    : []
                }
                pagination={{
                  position: ["bottomRight"],
                  total: lightboxs.length,
                  showTotal: (total) => `Tổng ${total} tủ`,
                  defaultPageSize: 20,
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span className="text-base">
                  <ScheduleOutlined />
                  Lịch sử đăng kí
                </span>
              }
              key="2"
            >
              <RegisterHistory data={registerLogs} />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="absolute bg-variant-white p-4 z-10 left-[10px] md:left-[530px] top-[380px] md:top-[92px] md:max-w-[1580px] shadow-lg rounded-md">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-center md:min-w-[900px]">
            <Select
              className="h-[40px] min-w-[260px]"
              size="large"
              defaultOpen={false}
              showArrow={false}
              allowClear
              showSearch
              filterOption={false}
              placeholder="Tìm tủ..."
              onSearch={(value) => {
                setFilterInput(value);
              }}
              onChange={(e) => {
                setSelectedLightbox(e);
                // setFilterInput(e.target.value);
                // setCurrentPage(1);
              }}
            >
              {filterText === ""
                ? []
                : searchlightboxs.map((i: any) => (
                    <Select.Option
                      key={i.id}
                      value={i.id}
                      label={`${i.boxId} - ${i.name}`}
                    >
                      {i.boxId} - {i.name}
                    </Select.Option>
                  ))}
            </Select>
            <Select
              className="h-[40px] w-[260px]"
              size="large"
              placeholder="Chọn dự án"
              onChange={(value) => {
                setFilter({ ...filter, projectId: value });
              }}
            >
              {projects.map((p: any) => (
                <Select.Option key={p.id} value={p.id}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
            <CheckboxGroup
              className="w-fit"
              defaultValue={[0, 1]}
              onChange={(values) => {
                if (values.length === 1) {
                  setFilter({ ...filter, isRegistered: values[0] === 1 });
                  setShowItems({
                    ...showItems,
                    unregisteredboxs: values[0] !== 1,
                    registeredboxs: values[0] === 1,
                  });
                } else {
                  setFilter({ ...filter, isRegistered: undefined });
                  setShowItems({
                    ...showItems,
                    unregisteredboxs: values.length === 2,
                    registeredboxs: values.length === 2,
                  });
                }
              }}
            >
              <Checkbox className="text-lg" value={0}>
                Chưa đăng kí
              </Checkbox>
              <Checkbox className="text-lg" value={1}>
                Đã đăng kí
              </Checkbox>
            </CheckboxGroup>
            <Checkbox
              className="text-lg border-solid border-0 border-l-2 px-4"
              defaultChecked
              onChange={() =>
                setShowItems({ ...showItems, devices: !showItems.devices })
              }
            >
              Thiết bị
            </Checkbox>
          </div>
        </div>
        <GMap
          lightboxs={lightboxs}
          devices={devices}
          showItems={showItems}
          onDeviceClick={handleClick}
          selectedLightbox={selectedLightbox}
        />
        <CreateRemoteRegisterModal
          isModalVisible={isModalVisible}
          selectedItem={selected}
          setIsModalVisible={setIsModalVisible}
          onCompleted={({ registerLog, clientId, lightboxId }: any) => {
            // refetch();
            const deviceIndex: any = devices.findIndex(
              (i: any) => i.id === clientId
            );
            const d = devices[deviceIndex];
            const devicesTemp = [...devices];
            devicesTemp.splice(deviceIndex, 1);
            setDevices(devicesTemp);

            const lbIndex = lightboxs.findIndex(
              (i: any) => i.id === lightboxId
            );
            let lightbox: any = {
              ...lightboxs[lbIndex],
              clientId,
              isRegistered: true,
              lat: d.liveData.lat,
              lng: d.liveData.lng,
            };
            const temp = [...lightboxs];
            temp.splice(lbIndex, 1);
            setLightboxs([lightbox, ...temp]);
            setRegisterLogs([
              { ...registerLog, key: registerLog.id },
              ...registerLogs,
            ]);
          }}
          filter={filter}
        />
      </div>
    </>
  );
};

export default RemoteRegister;
