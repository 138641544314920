import * as React from "react";

import {
  CloseCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Avatar,
  Divider,
  List,
  Skeleton,
} from "antd";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CLEAR_NOTIFICATIONS,
  GET_ACTIVE_NOTIFICATIONS,
  MARK_NOTIFICATIONS_VIEWED,
} from "../../../graphql/notification";
import "./index.css";
import DeviceDetailModal from "../DetailModal";
import { GET_LIGHTBOX } from "../../../graphql/lightboxs";

interface INotificationProps {
  initialData?: any[];
  subscriptionData: any[];
}

interface IReadmoreProps {
  children: any;
}

const ReadMore: React.FunctionComponent<IReadmoreProps> = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text whitespace-pre-line">
      {isReadMore ? text.slice(0, 70) : text}
      {text.length > 70 && (
        <span
          onClick={toggleReadMore}
          className="text-blue-primary cursor-pointer"
        >
          {isReadMore ? "...xem thêm" : " ẩn bớt"}
        </span>
      )}
    </p>
  );
};

const Notification: React.FunctionComponent<INotificationProps> = ({
  initialData,
  subscriptionData,
  ...props
}) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<any>();
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [maxCount, setMaxCount] = React.useState(100);
  const [markNotificationViewed] = useMutation(MARK_NOTIFICATIONS_VIEWED);
  const [clearNotifications] = useMutation(CLEAR_NOTIFICATIONS);
  const [loadMoreNotifications, { loading }] = useLazyQuery(
    GET_ACTIVE_NOTIFICATIONS,
    {
      onCompleted(data) {
        setDataSource((pre: any[]) => {
          const uniqueIds: any[] = [];
          return [...pre, ...data.activeNotifications].filter((element) => {
            const isDuplicate = uniqueIds.includes(element.id);
            if (!isDuplicate) {
              uniqueIds.push(element.id);
              return true;
            }
            return false;
          });
        });
        // setMaxCount(data.activeNotificationsCount);
      },
    }
  );

  const [getLightbox] = useLazyQuery(GET_LIGHTBOX, {
    onCompleted(data) {
      setSelectedItem(data.lightbox);
      setShowDetail(true);
    },
  });

  React.useEffect(() => {
    setDataSource((pre) => [...subscriptionData, ...pre]);
  }, [subscriptionData]);

  React.useEffect(() => {
    loadMoreData();
  }, [subscriptionData]);

  const loadMoreData = () => {
    if (loading) return;
    loadMoreNotifications({
      variables: {
        paging: {
          count: 10,
          skip: dataSource.length,
        },
      },
    });
  };

  return (
    <div className={`overflow-auto h-[800px]`} id="notificationDiv">
      {selectedItem && (
        <DeviceDetailModal
          visible={showDetail}
          onCancel={() => {
            setShowDetail(false);
          }}
          lightboxId={selectedItem.id}
        />
      )}
      <InfiniteScroll
        dataLength={dataSource.length}
        next={loadMoreData}
        hasMore={dataSource.length < maxCount}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>Kết thúc</Divider>}
        scrollableTarget="notificationDiv"
      >
        <List
          // className="h-full"
          itemLayout="vertical"
          // loadMore={loadMore}
          dataSource={dataSource}
          renderItem={(i, index) => (
            <List.Item
              key={i.id}
              className={`px-2 rounded-lg hover:bg-neutral-l90`}
              actions={[
                <span>
                  {moment(i.createdAt)
                    .utcOffset(7, false)
                    .format("YYYY-MM-DD HH:mm")}
                </span>,
              ]}
              extra={
                <div className="flex flex-col justify-between items-center h-full !z-999">
                  <div
                    onClick={() => {
                      clearNotifications({
                        variables: {
                          ids: [i.id],
                        },
                      });
                      let temp = dataSource.filter((item) => item.id !== i.id);
                      setDataSource(temp);
                    }}
                    className="text-xl text-neutral-d50 cursor-pointer hover:text-neutral-d90 !z-999"
                  >
                    <CloseCircleFilled />
                  </div>

                  {!i.viewed && (
                    <div
                      className={`h-4 w-4 rounded-full bg-blue-secondary`}
                    ></div>
                  )}
                  <div></div>
                </div>
              }
            >
              <div
                onClick={() => {
                  i.objectId && getLightbox({ variables: { id: i.objectId } });
                  if (!dataSource[index].viewed) {
                    markNotificationViewed({
                      variables: {
                        notificationId: i.id,
                      },
                    });
                    const temp = JSON.parse(JSON.stringify(dataSource));
                    temp[index].viewed = true;
                    setDataSource(temp);
                  }
                }}
                className="cursor-pointer"
              >
                <Skeleton avatar loading={false} active={false}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        className="text-variant-error"
                        icon={<WarningFilled />}
                      />
                    }
                    title={
                      <div>
                        <span className="font-bold">{i.title} </span>
                        <span>{i.message}</span>
                      </div>
                    }
                  />
                </Skeleton>
              </div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default Notification;
