import { Button, Modal, Switch, Table, Tabs, TabsProps, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as _ from "lodash";
import * as React from "react";
import { IncidentContext } from "../../../../context/IncidentContext";
import { DeviceState } from "../../../../graphql/lightboxs";
import { sortByKey } from "../../../../utils/helpers";
import { getColorCssByState } from "../map/helpers";
import CabinetStatisticDetail from "./detail";
import "./modal.css";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_INCIDENTS_DASHBOARD,
  GET_INCIDENT_TYPES,
} from "../../../../graphql/incident";
import { AuthContext } from "../../../../context/AuthContext";
import { SyncOutlined } from "@ant-design/icons";
import { GET_REGIONS_ON_DEPLOY } from "../../../../graphql/location";

interface IStatisticCabinetModalProps {
  open?: boolean;
  data: any[];
  activeKey: DeviceState;
  handleCancel: () => void;
  handleSelectItem: (id: number) => void;
}

const StatisticCabinetModal: React.FunctionComponent<
  IStatisticCabinetModalProps
> = ({
  open = false,
  handleCancel,
  handleSelectItem,
  activeKey,
  data,
  ...props
}: IStatisticCabinetModalProps) => {
  const [currentTab, setCurrentTab] = React.useState<DeviceState>(activeKey);
  const prevTab = React.useRef<DeviceState>(activeKey);
  const [expandKeys, setExpandKeys] = React.useState<string[]>([]);
  const [checkNewOpen, setCheckNewOpen] = React.useState(true);
  const [showAllIncident, setShowAllIncident] = React.useState(false);
  const { userInfo } = React.useContext(AuthContext);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [text, setText] = React.useState("");
  const [reloading, setReloading] = React.useState(false);
  const [incidents, setIncidents] = React.useState([]);
  const [expandedIds, setExpandedIds] = React.useState<number[]>([]);
  const [incidentTypes, setIncidentTypes] = React.useState<any>([]);
  const { districts, provinces, setProvinces, setDistricts } =
    React.useContext(IncidentContext)!;

  useQuery(GET_REGIONS_ON_DEPLOY, {
    onCompleted(data) {
      setProvinces(data.regionsOnDeploy.provinces);
      const d: any = [...data.regionsOnDeploy.districts].sort(
        (a: any, b: any) => {
          const i1 = a.id;
          const i2 = b.id;
          return i1 > i2 ? 1 : i1 < i2 ? -1 : 0;
        }
      );
      setDistricts(d);
    },
  });

  useQuery<any, any>(GET_INCIDENT_TYPES, {
    onCompleted(data) {
      setIncidentTypes(data.incidentTypes);
    },
  });
  const [getIncidents, { loading, startPolling, stopPolling }] = useLazyQuery<
    any,
    any
  >(GET_INCIDENTS_DASHBOARD, {
    fetchPolicy: "no-cache",
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onCompleted(incidentData) {
      setIncidents(incidentData.lightboxsWithIncidents);
      if (incidentData) {
        setIncidents(incidentData.lightboxsWithIncidents);
      }
    },
  });

  const columns: ColumnsType<any> = [
    // {
    //   title: "",
    //   width: 20,
    // },
    {
      title: "Mã tủ",
      key: "boxId",
      //   width: 100,
      render: (
        text,
        { id, isParent, isProvince, area, boxId, level },
        index
      ) => {
        if (isParent) {
          let color = level ? "" : "text-blue-primary";
          let text = area ? area.displayName : "null";
          let textSize = "text-sm";
          let ml = "ml-0";
          if (level === 1) ml = "ml-4";
          if (level === 2) {
            ml = "ml-8";
            // textSize = "text-xs";
            text = `${id}`;
            // text = `${id}.01 → ${id}.20`;
          }
          return (
            <div className={`font-bold min-w-[100px] ${textSize} ${ml}`}>
              {text}
            </div>
          );
        }
        return (
          <span
            onClick={() => {
              handleSelectItem(id);
              handleCancel();
            }}
            className="hover:text-blue-primary hover:underline hover:font-bold cursor-pointer"
          >
            {boxId}
          </span>
        );
      },
    },
    {
      title: "Tên tủ",
      key: "name",
      render: (_, { id, isParent, name }) => {
        if (isParent) return <></>;
        return (
          <span
            onClick={() => {
              handleSelectItem(id);
              handleCancel();
            }}
            className="hover:text-blue-primary hover:underline hover:font-bold cursor-pointer"
          >
            {name}
          </span>
        );
      },
    },
    {
      title: "Số lượng",
      key: "count",
      render: (_, { count, incidents }) => {
        if (incidents) {
          return incidents.map((i: any) => {
            const _incidentType = incidentTypes.find((t: any) => t.id === i);
            return (
              <Tag
                key={i}
                title={_incidentType.description}
                className="text-base break-all cursor-pointer my-0"
                color={_incidentType.isCustom ? "blue" : "volcano"}
              >
                {_incidentType.name}
              </Tag>
            );
          });
        }
        if (!count) return <></>;
        let colorCount = getColorCssByState(currentTab);
        return <div className={`font-bold ${colorCount}`}>{count}</div>;
      },
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: DeviceState.ERROR,
      label: (
        <div className="md:px-4">
          <CabinetStatisticDetail type={DeviceState.ERROR} />
        </div>
      ),
    },
    {
      key: DeviceState.NORMAL,
      label: (
        <div className="md:px-4">
          <CabinetStatisticDetail type={DeviceState.NORMAL} />
        </div>
      ),
      //   children: "Content of Tab Pane 2",
    },
    {
      key: DeviceState.LIGHT_ON,
      label: (
        <div className="md:px-4">
          <CabinetStatisticDetail type={DeviceState.LIGHT_ON} />
        </div>
      ),
      //   children: "Content of Tab Pane 3",
    },
    // {
    //   key: DeviceState.SAVING_ENERGY,
    //   label: (
    //     <div className="md:px-4">
    //       <CabinetStatisticDetail type={DeviceState.SAVING_ENERGY} />
    //     </div>
    //   ),
    //   //   children: "Content of Tab Pane 3",
    // },
  ];

  React.useEffect(() => {
    let text = "";
    switch (currentTab) {
      case DeviceState.ERROR:
        // getIncidents();
        // onChange(incidents, currentTab);
        text = "lỗi/mất kết nối";
        break;
      case DeviceState.NORMAL:
        text = "nghỉ";
        break;
      case DeviceState.LIGHT_ON:
        text = "sáng đèn";
        break;
      default:
        text = "tiết giảm";
        break;
    }
    setText(text);
    const isTabChange = prevTab.current !== currentTab;
    onChange(data, incidents, currentTab, isTabChange);
    prevTab.current = currentTab;
    // if (data && currentTab !== DeviceState.ERROR) {
    //   onChange(data, currentTab);
    // }
  }, [data, currentTab, incidents]);

  React.useEffect(() => {
    if (open) {
      if (expandedIds.length) {
        startPolling(10000);
      }
    } else {
      stopPolling();
    }
  }, [open]);

  React.useEffect(() => {
    setCurrentTab(activeKey);
  }, [activeKey]);
  const onChange = (
    source: any[],
    incidentBoxs: any[],
    key: string,
    isTabChange: boolean
  ) => {
    let _data;
    _data = source
      .filter((i: any) => i.device.currentState === key)
      .map((lightbox) => {
        const incidentItem = incidentBoxs.find((box) => box.id === lightbox.id);
        const incidents = incidentItem
          ? incidentItem.incidentTypeIds
          : undefined;
        return {
          ...lightbox,
          key: lightbox.id,
          incidents,
        };
      });
    // if (key === DeviceState.ERROR && showAllIncident) {
    //   _data = source.filter(
    //     (i) => !!incidentBoxs.find((box) => box.id === i.id)
    //   );
    //   _incidentBoxs = incidentBoxs;
    // } else {
    //   _data = source.filter((i: any) => i.device.currentState === key);
    //   _incidentBoxs = incidentBoxs.filter((box) => {
    //     const errorCondition = box.incidentTypeIds.some((typeId: number) =>
    //       [1, 5, 8].includes(typeId)
    //     );
    //     return key === DeviceState.ERROR ? errorCondition : !errorCondition;
    //   });
    // }
    // _data = _data.map((lightbox: any) => {
    //   const incidentItem = _incidentBoxs.find((box) => box.id === lightbox.id);
    //   const incidents = incidentItem ? incidentItem.incidents : undefined;
    //   return {
    //     ...lightbox,
    //     key: lightbox.id,
    //     incidents,
    //   };
    // });
    const grouped = _.groupBy(_data, (i) => i.provinceId);
    const data1 = Object.entries(grouped).map(([key, value]) => {
      const group_district = _.groupBy(value, (i) => i.districtId);
      const dataDistrict = Object.entries(group_district).map(
        ([key_d, value_d]) => {
          const group_boxId = _.groupBy(value_d, (i) => i.boxId.split(".")[0]);
          const dataBoxId = Object.entries(group_boxId).map(
            ([key_b, value_b]) => {
              return {
                id: key_b,
                key: key_b,
                count: value_b.length,
                area: districts.find((i) => i.id === value_d[0].districtId),
                level: 2,
                isParent: true,
                children: sortByKey(value_b, "boxId"),
              };
            }
          );
          return {
            id: key_d,
            key: key_d,
            count: value_d.length,
            area: districts.find((i) => i.id === value_d[0].districtId),
            level: 1,
            isParent: true,
            children: dataBoxId,
          };
        }
      );
      const dataDistrictSorted = sortByKey(dataDistrict, "key");
      return {
        id: key,
        key: key,
        count: value.length,
        area: provinces.find((i) => i.id === value[0].provinceId),
        level: 0,
        isParent: true,
        children: dataDistrictSorted,
      };
    });
    const result = sortByKey(data1, "key");
    setDataSource(result);
    if (isTabChange) {
      if (result.length) {
        const expandKeys = [result[0].id];
        // if (result[0].children && result[0].children.length) {
        //   expandKeys.push(result[0].children[0].id);
        // }
        setExpandKeys(expandKeys);
        setCheckNewOpen(false);
        setExpandedIds([]);
      }
    }
    // if (checkNewOpen && result.length && !expandKeys.includes(result[0].id)) {
    //   setExpandKeys((expandKeys) => [...expandKeys, result[0].id]);
    // }
    // setCheckNewOpen(false);
  };

  React.useEffect(() => {
    if (districts.length && provinces.length) {
      setReloading(false);
    } else {
      setReloading(true);
    }
  }, [districts, provinces]);

  React.useEffect(() => {
    if (expandedIds.length) {
      getIncidents({ variables: { lightboxIds: expandedIds } });
    }
  }, [expandedIds]);

  const handleRefresh = () => {
    const source = [...dataSource];
    setDataSource([]);
    setReloading(true);
    setExpandKeys([]);
    setTimeout(() => {
      setDataSource(source);
      setReloading(false);
    }, 2000);
  };

  return (
    <Modal
      className="CabinetStatisticModal !w-max md:!w-min"
      title={
        <span className="text-blue-primary">
          Thống kê nhanh trạng thái thiết bị
        </span>
      }
      destroyOnClose={false}
      open={open}
      centered
      footer={false}
      onCancel={handleCancel}
    >
      <Tabs
        activeKey={currentTab}
        items={items}
        type="card"
        onTabClick={(key: any) => setCurrentTab(key)}
      ></Tabs>
      <div className="flex gap-4 items-center pb-2">
        <div className={`font-semibold ${getColorCssByState(currentTab)}`}>
          Danh sách tủ trong trạng thái {text}
        </div>
        <Button
          title="Tải lại"
          icon={<SyncOutlined />}
          onClick={handleRefresh}
        ></Button>
      </div>
      {/* {userInfo!.isSuperAdmin && currentTab === DeviceState.ERROR && (
        <div className="flex gap-1 items-center pb-2">
          <span className="italic">Hiện toàn bộ lỗi: </span>
          <Switch
            checked={showAllIncident}
            onChange={(checked) => {
              setShowAllIncident(checked);
            }}
            checkedChildren="Có"
            unCheckedChildren="Không"
          />
        </div>
      )} */}
      <Table
        // onRow={contextMenuStyle ? onRowHandle : undefined}
        loading={
          reloading ||
          (loading && currentTab === DeviceState.ERROR && !dataSource.length)
        }
        scroll={{ y: 500 }}
        expandable={{
          // indentSize: 48,
          // showExpandColumn: false,
          expandRowByClick: true,
          expandedRowKeys: expandKeys,
          onExpandedRowsChange(expandedKeys: any) {
            setExpandKeys(expandedKeys);
          },
          onExpand(expanded, record) {
            if (expanded && record.level === 2) {
              const newIds = new Set([
                ...expandedIds,
                ...record.children.map((i: any) => i.id),
              ]);
              setExpandedIds(Array.from(newIds));
            }
          },
        }}
        className="overflow-x-auto min-w-[50vw] min-h-[70vh]"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
      />
    </Modal>
  );
};

export default StatisticCabinetModal;
